import React, { Component } from 'react'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import * as confusion_matrix_algo from "./00_confusion_matrix_algo"
export class ConfusionMatrixContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { matrix_data: null, }
    }
    componentDidMount() {
        this.setState({ a: 10 })
    }
    componentDidUpdate() {
        var selected_data = this.props.selected_data
        var temp_confusionMatrix={}
        if(this.props.split_by=="None"){
            this.props.selected_models.map(model=>{
                temp_confusionMatrix[model]=confusion_matrix_algo.get_confusionMatrix2(model, selected_data, this.props.selected_DT)
            })
        }
        else{
            this.props.selected_models.map(model=>{
                temp_confusionMatrix[model]=confusion_matrix_algo.get_confusionMatrix(model, selected_data, this.props.selected_DT,this.props.split_by) // here split_by is the subgroup
            })    
        }
        var final_confusionMatrix={"TPs":[],"FPs":[],"TNs":[],"FNs":[]}
        Object.entries(temp_confusionMatrix).map(item=>{
            final_confusionMatrix["TPs"].push([item[0],item[1]['TP']])
            final_confusionMatrix["FPs"].push([item[0],item[1]['FP']])
            final_confusionMatrix["TNs"].push([item[0],item[1]['TN']])
            final_confusionMatrix["FNs"].push([item[0],item[1]['FN']])
        })
        //console.log("final_confusionMatrix",final_confusionMatrix)
        if (this.props.split_by == "None") { 
            confusion_matrix_algo.Creatematrix(final_confusionMatrix,this.props.selected_models) // Creates confusion matrix      
        }
        else {
            confusion_matrix_algo.CreatematrixCat(final_confusionMatrix,this.props.selected_models,this.props.split_by,this.props.subgroups) // Creates confusion matrix for categorical values
        }
    }
    render() {
        return (
            <Grid 
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginTop: 10}}>
                <svg className="legend"></svg>
                <svg className="parent_container" key={this.props.split_by}></svg>
            </Grid>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        selected_models: state.selected_models,
        split_by: state.split_by,
        selected_data:state.selected_data,
        selected_DT:state.selected_DT,
        subgroup:state.subgroup,
        subgroups:state.subgroups,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_defualt_models: (val) => dispatch({ type: "selected_models", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(ConfusionMatrixContainer);

// https://bl.ocks.org/hsiaoyi0504/1b599d44deab7e68328b057c47abe47c
