import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import * as d3 from 'd3';
import * as $ from 'jquery';
import * as algo1 from '../../Algorithms/Algo1';
import DataCluster from "./DataCluster/DataCluster"
import LimeCluster from "./LimeCluster/LimeCluster"
export class App extends Component {
    constructor(props) {
        super(props)
        this.state = { selected_data: null }
    }
    componentDidMount() {
        
    }
    componentDidUpdate(){
        
    }
    render() {
        return (
            <Grid container direction="row" justify="center" style={{marginTop:10,borderTop:"1px solid #d6d6d6"}} alignItems="center" spacing={0}>
                <Grid>
                <p style={{margin:0,marginLeft:"42%",marginTop:5,fontWeight:'bold'}}>Ground Truth</p>
                {this.props.selected_data.length>0 && this.props.subgroups.length>0?<DataCluster Set_selected_cluster={this.props.Set_selected_cluster} split_by={this.props.split_by=="None"?"gender":this.props.split_by} subgroups={this.props.subgroups} selected_data={this.props.selected_data} url={this.props.url} config={this.props.config}></DataCluster>:null}
                </Grid>
                <Grid>
                <p style={{margin:0,marginLeft:"40%",marginTop:5,fontWeight:'bold'}}>Lime Explanation</p>
                {this.props.lime_data.length!=0 && this.props.subgroups.length>0?<LimeCluster Set_selected_cluster={this.props.Set_selected_cluster} selected_cluster={this.props.selected_cluster} lime_data={this.props.lime_data} split_by={this.props.split_by=="None"?"gender":this.props.split_by} subgroups={this.props.subgroups} selected_data={this.props.selected_data} url={this.props.url} config={this.props.config}></LimeCluster>:null}
            </Grid >
            </Grid >
        )
    }
}
const maptstateToprop = (state) => {
    return {
        selected_data: state.selected_data,
        original_data: state.original_data,
        subgroups:state.subgroups,
        split_by:state.split_by,
        url:state.url,
        lime_data:state.lime_data,
        config:state.config,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_selected_cluster: (val) => dispatch({ type: "selected_cluster", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);
