import React, { Component } from 'react'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import * as d3 from 'd3';
import * as $ from 'jquery';
import * as confusion_matrix_algo from "../ConfusionMatrix/00_confusion_matrix_algo"
export class Bar extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        this.setState({ dummy: 10 })
    }
    componentDidUpdate() {
        var rect_height = 30;
        var filtered_selected_data = this.props.selected_data.filter(item => item[this.props.split_by] == this.props.subgroup)
        var result = confusion_matrix_algo.get_confusionMatrix2(this.props.model, filtered_selected_data, this.props.selected_DT[this.props.model][this.props.subgroup])
        var accuracy = result.TP.length / (result.TP.length + result.TN.length)
        var precision = result.TP.length / (result.TP.length + result.FP.length)
        var recall = result.TP.length / (result.TP.length + result.FN.length)
        var F1 = (2 * precision * recall) / (precision + recall)
        //console.log("F1 score is ", F1)
        var myscale = d3.scaleLinear().domain([0, 1]).range([0, rect_height])
        d3.selectAll("#" + (this.props.model + this.props.subgroup + this.props.type).replace(/\s/g, '')).selectAll('rect').data([0]).join('rect').attr('y', rect_height - myscale(F1)).attr('height', myscale(F1)).attr('width', this.props.model_container_width / 2 - 1).attr('fill', '#b7b7b7')
        d3.selectAll("#" + (this.props.model + this.props.subgroup + this.props.type).replace(/\s/g, '')).selectAll('text').data([0]).join('text').attr('x', this.props.model_container_width / 4).attr("y", 45).attr("text-anchor", "middle").text(this.props.subgroup).attr('font-size', 14)
        d3.selectAll("#" + (this.props.model + this.props.subgroup+ this.props.type).replace(/\s/g, '')).selectAll('.text2').data([0]).join('text').attr('class','text2').attr('x', this.props.model_container_width / 4).attr("y", rect_height/2).attr("text-anchor", "middle").text(F1.toFixed(3)).attr('font-size', 10)
    }
    render() {
        return (
            <svg id={(this.props.model + this.props.subgroup + this.props.type).replace(/\s/g, '')} style={{ width: this.props.model_container_width / 2, height: 50 }}></svg>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        split_by: state.split_by,
        selected_data: state.selected_data,
        selected_DT: state.selected_DT,

    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_selected_DT: (val) => dispatch({ type: "selected_DT", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(Bar);

// https://bl.ocks.org/hsiaoyi0504/1b599d44deab7e68328b057c47abe47c
