import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button'
import {connect} from 'react-redux'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export function DenseAppBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Toolbar variant="dense" style={{backgroundColor:"#f2f2f2",borderBottom:"1px solid #c1c1c1",minHeight:36,paddingLeft:0}}>
        <Button  style={{borderRadius:0,boxShadow:"none",marginLeft:0}}>View</Button>
        <Button  style={{borderRadius:0,boxShadow:"none", borderLeft:"1px solid #d3d3d3",marginLeft:0,padding:"6px 8px"}}
        onClick={()=>props.Set_open_uploader(true)}
        >Upload</Button>
        </Toolbar>
    </div>
  );
}
const maptstateToprop = (state) => {
  return {
    open_uploader:state.open_uploader
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      Set_open_uploader: (val) => dispatch({ type: "open_uploader", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(DenseAppBar);