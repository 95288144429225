import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function FadeMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //------------For list and chckbox
  const [checked, setChecked] = React.useState([0]);
  const [selected_state, Set_selected_state] = React.useState("Male");
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  var handleChange=(event)=>{
    Set_selected_state(event.target.value)
    props.Set_lime_model(event.target.value)
  }
  return (
        <div>
            <Button style={{borderRadius:0,textTransform:"none"}} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>{props.lime_model}<ArrowDropDownIcon></ArrowDropDownIcon>
      </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div className="radioContainer" style={{padding:"0px 15px"}}>
                <RadioGroup aria-label="gender" name="gender1" value={props.lime_model} 
                onChange={(event)=>handleChange(event)}>
                    {props.selected_models.map((value) => {
                        return <FormControlLabel value={value} control={<Radio />} label={value} />
                    })}
                </RadioGroup>
                </div>
            </Menu>
        </div>
    );
}
const maptstateToprop = (state) => {
    return {
        lime_model:state.lime_model,
        selected_models:state.selected_models
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_lime_model: (val) => dispatch({ type: "lime_model", value: val }),        
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(FadeMenu);
//https://material-ui.com/components/menus/
