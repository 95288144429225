import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import * as d3 from 'd3';
import * as algo1 from '../../../Algorithms/Algo1';
import Textbox2 from "./Textbox2"
import { connect } from 'react-redux'
export class App extends Component {
    constructor(props) {
        super(props)
        this.state = { number_of_clusters: 5 }
    }
    componentDidMount() {
        this.setState({ a: 10 })
    }
    componentDidUpdate() {
        var my_inds = this.props.lime_data[this.props.lime_model].map(item => parseInt(item["unique_index"]))
        var data = { "dataset": "compas", "k": this.state.number_of_clusters,split_by: this.props.split_by}
        data['my_inds'] = my_inds
        var response = algo1.downlaod(this.props.url + "/get_lime_cluster", data)
        var temp = []
        response.then(cluster_data => {
            //----------------------
            cluster_data["response"].map(element => {
                this.props.lime_data[this.props.lime_model].map(item => {
                    if (item['unique_index'] == element[0]) {
                        item['cluster'] = element[1]
                        temp.push(item)
                    }
                })
            })
            //----------------------Filter data based on cluster click
            if(this.props.selected_cluster.length>0){temp=temp.filter(item=>this.props.selected_cluster.includes(item['index']))}
            //----------------------
            var clustered = d3.nest().key(function (d) { return d.cluster}).entries(temp);
            algo1.make_cluster_vis2(this.props.config,clustered, this.props.subgroups, this.props.split_by+"_val", "cluster_container2")
        })

    }
    set_number_of_clusters=(number)=>{
        this.setState({number_of_clusters:parseInt(number)})
    }
    render() {
        return (
            <Grid container direction="row" spacing={0} style={{ paddingLeft:3,marginTop: 10, marginLeft: 10, border: "1px solid #e0e0e0", width: this.props.config.LimeClusterWidth }}>
                <Textbox2 Set_selected_cluster={this.props.Set_selected_cluster} set_number_of_clusters={this.set_number_of_clusters} number_of_clusters={this.state.number_of_clusters}></Textbox2>
                <svg className="cluster_container2" style={{marginBottom:7,marginRight:7}}> </svg>
            </Grid >
        )
    }
}
const maptstateToprop = (state) => {
    return {
        lime_model:state.lime_model,
        selected_cluster:state.selected_cluster
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_selected_cluster: (val) => dispatch({ type: "selected_cluster", value: val }),
    }
}

export default connect(maptstateToprop, mapdispatchToprop)(App);
