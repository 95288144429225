import React, { Component } from 'react'
import * as d3 from 'd3';
import { connect } from 'react-redux'
import * as algo1 from "../../Algorithms/Algo1"
import * as $ from 'jquery'
export class NumHistogram extends Component {
    constructor(props) {
        super(props)
        this.state = {
            temp_data: { "AGE": { "17": 1, "26": 32, "36": 46, "45": 28, "55": 17, "64": 13, "74": 3 }, "RACE": { "A": 1, "B": 33, "U": 125, "W": 124 } }
        }
    }
    componentDidMount() {
        var mywidth=$(".sidebar").width()
        var diff=this.linspace(d3.max(this.props.original_data.map(item => parseInt(item[this.props.title]))),d3.min(this.props.original_data.map(item => parseInt(item[this.props.title]))),7)
        var temp_arr=[]
        diff.map((item,ind)=>{
            var temp_dict={}
            temp_dict["key"]=parseInt(item)
            if(ind!=0){
                var res=this.props.original_data.filter(instance=>instance[this.props.title]<item && instance[this.props.title]>diff[ind-1])
                temp_dict["value"]={"count":res.length,"instances":res}  
            }
            else{
                var res=this.props.original_data.filter(instance=>instance[this.props.title]<=item)
                temp_dict["value"]={"count":res.length,"instances":res}
            }
            temp_arr.push(temp_dict)  
        })
        this.create_histogram(temp_arr,mywidth)
    }
    linspace = (start, stop, nsteps)=>{
        var delta = (stop-start)/(nsteps-1)
        return d3.range(start, stop+delta, delta).slice(0, nsteps).map(item=>item.toFixed(2)).sort()
      }
    create_histogram = (data) => { // data is {key,value} key is the x values and value is the y
        var margin = { top: 10, right: 10, bottom: 50, left: 10 },
            width = $('.feature_container').width() - margin.left - margin.right-15,
            height = 130 - margin.top - margin.bottom;

        var svg = d3.select("#" + this.props.feature_id).attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom) //----------------------------- Get svg x scale and y scale
        var x = d3.scaleBand().domain(data.map(item => item.key)).range([0, width]).padding(0.01)
        var y = d3.scaleLinear().domain([0, d3.max(data.map(item => item.value.count))]).range([height, 0]);

        var xAxis = d3.select("#" + "myXaxis" + this.props.feature_id).attr("transform", "translate(" + margin.left + "," + height + ")") //-------------------------------------------------- X axis
        xAxis.call(d3.axisBottom(x))
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", "-.55em")
            .attr("transform", "rotate(-90)")
            .selectAll(".tick line").remove()

        var yAxis = d3.select("#" + "myYaxis" + this.props.feature_id).attr("class", "myYaxis").attr("transform", "translate(" + margin.left + "," + "0" + ")") //--------------------------------------------------Y axis
        yAxis.call(d3.axisLeft(y).ticks(5)).selectAll(".tick,.domain").remove();

        svg.selectAll("rect").data(data, d => d.key).join('rect')
                    .attr("x", d=>x(d.key) + margin.left)
                    .attr("y", function (d) { return y(d.value.count) })
                    .attr("width", x.bandwidth())
                    .attr("height", function (d) { return height - y(d.value.count) })
                    .attr("fill", "#c9c9c9")
                    .attr('class',d=>this.props.feature_id+d.key)
                    .on('click',d=>{
                        d3.select("."+this.props.feature_id+d.key)
                        .classed("rect_selected", d=>{
                            if(d3.select("."+this.props.feature_id+d.key).classed("rect_selected")){
                                var temp=this.props.selected_rects
                                delete temp[this.props.feature_id+d.key]
                                this.props.Set_selected_rects(temp)
                                this.props.Set_selected_data(algo1.combine_dict_of_arrays(temp))
                                return false;
                            }
                            else{
                               var temp=this.props.selected_rects
                                temp[this.props.feature_id+d.key]=d.value.instances
                                this.props.Set_selected_rects(temp)
                                this.props.Set_selected_data(algo1.combine_dict_of_arrays(temp))
                                return true;
                            }
                        })
                       
                    })
    }
    render() {
        return (
            <div style={{ marginTop: 10 }}>
                <p style={{ marginLeft: "35%", marginTop: 0, marginBottom: 10 }}>{this.props.title}</p>
                <svg id={this.props.feature_id}>
                    <g id={"myXaxis" + this.props.feature_id}></g>
                    <g id={"myYaxis" + this.props.feature_id}></g>
                </svg>
            </div>
        )
    }
}

const maptstateToprop = (state) => {
    return {
        selected_rects: state.selected_rects,
    }
  }
  const mapdispatchToprop = (dispatch) => {
    return {
      Set_selected_rects: (val) => dispatch({ type: "selected_rects", value: val }),
      Set_selected_data: (val) => dispatch({ type: "selected_data", value: val }),      
    }
  }
  export default connect(maptstateToprop, mapdispatchToprop)(NumHistogram);
