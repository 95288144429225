import React, { Component } from 'react'
import { connect } from 'react-redux'
import Chart from "./Chart"
export class ConfusionMatrix2 extends Component {
    constructor(props) {
        super(props)
        this.state = { selected_base: null, dummy: null }
    }
    componentDidMount() {
        this.setState({ dummy: 10 }) // Change the state so that the componentDidUpdate is called. 
    }
    componentDidUpdate() {
    }
    //----------------------------------------
    render() {
        var filtered_subgroups=this.props.subgroups.filter(item=>item!=this.props.selected_base)
        return (
            <div>
                {filtered_subgroups.map(y_subgroup=> <Chart tolerence={this.props.tolerence} original_data={this.props.selected_data.length>0?this.props.selected_data:this.props.original_data} y_subgroup={y_subgroup} metricName={this.props.metricName}> </Chart>)}
            </div>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        subgroups:state.subgroups,
        selected_base:state.selected_base,
        original_data:state.original_data,
        selected_data:state.selected_data,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_scatter_plot_data: (val) => dispatch({ type: "scatter_plot_data", value: val })
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(ConfusionMatrix2);

// https://mljs.github.io/confusion-matrix/
