import React, { Component } from 'react'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import * as confusion_matrix_algo from "../ConfusionMatrix/00_confusion_matrix_algo"
import ModelContainer from "./ModelContainer";

export class SliderContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { selected_DT: null, selected_models: "SVM" }
    }
    componentDidMount() {
        this.setState({ selected_DT: this.state.selected_DT })
    }
    componentDidUpdate() {
        var result = confusion_matrix_algo.get_confusionMatrix2(this.props.selected_models, this.props.selected_data, this.props.selected_DT, this.props.split_by)
    }
    render() {
        return (
            <Grid container direction="row"  justify="center" alignItems="self-start" style={{border:"1px solid #bfbfbf", marginTop: 0, marginBottom: 0 }}>
                {this.props.selected_models.map(model => {
                    return <div>
                        <ModelContainer Set_selected_DT={this.props.Set_selected_DT} selected_DT={this.props.selected_DT} model={model} selected_data={this.props.selected_data} split_by={this.props.split_by}></ModelContainer>
                    </div>
                }
                )}

            </Grid>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        selected_models: state.selected_models,
        split_by: state.split_by,
        selected_data: state.selected_data,
        selected_DT: state.selected_DT,
        subgroups: state.subgroups,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_selected_DT: (val) => dispatch({ type: "selected_DT", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(SliderContainer);

// https://bl.ocks.org/hsiaoyi0504/1b599d44deab7e68328b057c47abe47c
