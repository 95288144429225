import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import * as algo1 from "../../Algorithms/Algo1"

function FadeMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //------------For list and chckbox 
    var handleChange = (event) => {
        var subgroups = algo1.getSubgroups(props.original_data, event.target.value)
        //------------------------
        var temp_selected_DT = {} // This is to map subgroups to a defualt DT
        subgroups.map(item => { temp_selected_DT[item] = 0.5 })
        var final_selected_DT = {} // This is to map models to defualt DT
        props.model_list.map(mymodel => final_selected_DT[mymodel] = temp_selected_DT)
        console.log(JSON.stringify(final_selected_DT))
        props.Set_selected_DT(final_selected_DT)
        props.Set_subgroups(subgroups)
        props.Set_selected_base(subgroups[0])
        props.Set_split_by(event.target.value)
    }
    return (
        <div>
            <Button style={{ borderRadius: 0 }} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                Split By
                <ArrowDropDownIcon></ArrowDropDownIcon>
            </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div className="radioContainer" style={{ padding: "0px 15px" }}>
                    <RadioGroup aria-label="gender" name="gender1" value={props.split_by}
                        onChange={(event) => handleChange(event)}>
                        {props.category_names.map((value) => {
                            return <FormControlLabel value={value} control={<Radio />} label={value} />
                        })}
                    </RadioGroup>
                </div>
            </Menu>
        </div>
    );
}
const maptstateToprop = (state) => {
    return {
        selected_models: state.selected_models,
        category_names: state.category_names,
        split_by: state.split_by,
        original_data: state.original_data,
        model_list: state.model_list,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_defualt_models: (val) => dispatch({ type: "selected_models", value: val }),
        Set_split_by: (val) => dispatch({ type: "split_by", value: val }),
        Set_subgroups: (val) => dispatch({ type: "subgroups", value: val }),
        Set_selected_base: (val) => dispatch({ type: "selected_base", value: val }),
        Set_selected_DT: (val) => dispatch({ type: "selected_DT", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(FadeMenu);
//https://material-ui.com/components/menus/
