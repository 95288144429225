import { data } from "jquery"
import * as d3 from 'd3';
import { index, json } from "d3";
export function getModels(data) {
    var models = new Set()
    data.map(item => models.add(item['Model']))
    return Array.from(models)
}
/*
export function getCategory_names(data){
    var category_names=new Set()
    data.map(item=>category_names.add(item['CategoryName']))
    return Array.from(category_names)
}
*/
export function getCategories(data, category_names) {
    var categories = {}
    category_names.map(category_name => {
        var temp = new Set()
        data.map(item => temp.add(item[category_name]))
        categories[category_name] = Array.from(temp)
    })
    return categories
}
export function getDTs(data) {
    var DTs = new Set()
    data.map(item => DTs.add(item['DT']))
    return Array.from(DTs)
}
export function combine_dict_of_arrays(dict) {
    var temp = []
    Object.values(dict).map(item => { temp = temp.concat(item) })
    return (temp)
}
export function getSubgroups(data, split_by) {
    var Subcategory_names = new Set()
    data.map(item => Subcategory_names.add(item[split_by]))
    return Array.from(Subcategory_names)
}
export function make_cluster_vis(config, data, subgroups, split_by, container, Set_selected_cluster) {
    data.sort((a, b) => a.key - b.key)
    var width = config.DataClusterWidth, height = data.length * config.cluster_cell_height;
    var config = { marginFortitle: 100, }
    var container = d3.select("." + container).attr("width", width).attr("height", height)
    var svg1_height = height / data.length
    container.selectAll(".svg1").data(data).join('svg').attr("class", "svg1").attr("width", width).attr("height", svg1_height).attr("y", (d, i) => svg1_height * i)
        .attr("add_title", function (rect_data, i) {
            var key = parseInt(rect_data.key) + 1
            d3.select(this).selectAll("text").data([i]).join("text").attr("x", 5).attr("y", svg1_height / 1.5).text("Cluster " + key + " (" + rect_data['values'].length + ")")
                .attr('font-size', 13)
        })
        .attr("add_rects", function (rect_data, i) {
            var temp = {}
            subgroups.map(subcat => {
                rect_data["values"].map(item => {
                    if (subcat in temp) {
                        if (item[split_by] == subcat) { temp[subcat].push(item) }
                    }
                    else {
                        temp[subcat] = []
                        if (item[split_by] == subcat) { temp[subcat].push(item) }
                    }
                })
            })
            var data2 = {}
            Object.entries(temp).map(item => {
                data2[item[0]] = item[1].length
            })
            var stackedData = d3.stack().keys(Object.keys(data2))([data2])
            var color = d3.scaleOrdinal().domain(Object.keys(data2)).range(['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99'])
            var scale = d3.scaleLinear().domain([0, rect_data['values'].length]).range([config.marginFortitle, width]);
            // Define the div for the tooltip
            var div = d3.select("body").append("div").attr("class", "tooltip").style("opacity", 0);
            d3.select(this).selectAll("rect").data(stackedData).join("rect").attr("height", svg1_height).attr("fill", "none").attr("stroke", "#e0e0e0")
                .attr("width", (d, i) => {
                    return scale(d[0][1]) - scale(d[0][0])
                })
                .attr("x", (d, i) => scale(d[0][0]))
                .attr("data", (d, i) => JSON.stringify(d[0].data))
                .attr("fill", d => color(d.key))
                .on("mousemove", function (d) {
                    div.transition()
                        .duration(200)
                        .style("opacity", .9);
                    div.html(d.key + " : " + d[0].data[d.key])
                        .style("left", (d3.event.pageX) + "px")
                        .style("top", (d3.event.pageY - 35) + "px");
                })
                .on("mouseout", function (d) {
                    //d3.selectAll(".tooltip").remove()		
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
            //.on("click", d => alert(d[0].data[d.key]))
        })
        .attr("add_border", function (rect_data, i) {
            d3.select(this).selectAll('.border' + i).data([0]).join('rect').attr("class", "border border" + i).attr("x", config.marginFortitle+1).attr('y',1).attr("width", width - (config.marginFortitle+14.5)).attr("height", svg1_height-2).attr("fill", "none").attr("id", "border")
        })
        .on('click', function(d,i){
            d3.selectAll(".border").classed("border_selected", false);
            d3.select(".border"+i).classed("border_selected", d3.select(this).classed("border_selected") ? false : true);
            Set_selected_cluster(d["values"].map(item => item["index"]))
        })
}


export function make_cluster_vis2(config, data, subgroups, split_by, container) {
    data.sort((a, b) => a.key - b.key)
    var width = config.LimeClusterWidth, height = data.length * config.cluster_cell_height;
    var config = { marginFortitle: 100, }
    var container = d3.select("." + container).attr("width", width).attr("height", height)
    var svg1_height = height / data.length
    container.selectAll(".svg1").data(data).join('svg').attr("class", "svg1").attr("width", width).attr("height", svg1_height).attr("y", (d, i) => svg1_height * i)
        .attr("add_title", function (rect_data, i) {
            var key = parseInt(rect_data.key) + 1
            d3.select(this).selectAll("text").data([i]).join("text").attr("x", 5).attr("y", svg1_height / 1.5).text("Cluster " + key + " (" + rect_data['values'].length + ")")
                .attr('font-size', 13)
        })
        .attr("add_rects", function (rect_data, i) {
            var temp = {}
            subgroups.map(subcat => {
                rect_data["values"].map(item => {
                    if (subcat in temp) {
                        if (item[split_by] == subcat) { temp[subcat].push(item) }
                    }
                    else {
                        temp[subcat] = []
                        if (item[split_by] == subcat) { temp[subcat].push(item) }
                    }
                })
            })
            var data2 = {}
            Object.entries(temp).map(item => {
                data2[item[0]] = item[1].length
            })
            var stackedData = d3.stack().keys(Object.keys(data2))([data2])
            var color = d3.scaleOrdinal().domain(Object.keys(data2)).range(['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99'])
            var scale = d3.scaleLinear().domain([0, rect_data['values'].length]).range([config.marginFortitle, width]);
            // Define the div for the tooltip
            var div = d3.select("body").append("div").attr("class", "tooltip").style("opacity", 0);
            d3.select(this).selectAll("rect").data(stackedData).join("rect").attr("height", svg1_height).attr("fill", "none").attr("stroke", "#e0e0e0")
                .attr("width", (d, i) => {
                    return scale(d[0][1]) - scale(d[0][0])
                })
                .attr("x", (d, i) => scale(d[0][0]))
                .attr("data", (d, i) => JSON.stringify(d[0].data))
                .attr("fill", d => color(d.key))
                .on("mousemove", function (d) {
                    div.transition()
                        .duration(200)
                        .style("opacity", .9);
                    div.html(d.key + " : " + d[0].data[d.key])
                        .style("left", (d3.event.pageX) + "px")
                        .style("top", (d3.event.pageY - 35) + "px");
                })
                .on("mouseout", function (d) {
                    //d3.selectAll(".tooltip").remove()		
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
            //.on("click", d => alert(d[0].data[d.key]))
        })
        .on('click', d => console.log(d))
}

export function downlaod(myurl, data) {
    return fetch(myurl, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
        .then(response => {
            return response;
        }).catch(error => console.error('Error: from Json Handler', error));
}


export default getModels;