import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SetModel from "./SetModel"
import Split from "./Split"
import DT from "./DT/DT"
import SelectMetrics from "./SelectMetrics"
import theme from "./DT/theme";
import { ThemeProvider } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function DenseAppBar() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
    <div className={classes.root}>
        <Toolbar variant="dense" style={{backgroundColor:"#",marginBottom:1,paddingLeft:0,boxShadow:"1px 1px 0px 0px #BABABA",minHeight:36}}>
          <Typography variant="h5" color="inherit">
          </Typography>
          <SetModel></SetModel>
          <Split></Split>
          <SelectMetrics></SelectMetrics>
          {
            //<span style={{fontSize:14}}>DECISION THRESHOLD</span><DT></DT>
          }
        </Toolbar>
    </div>
    </ThemeProvider>
  );
}
