import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectBase from "./SelectBase"
import MetricContainer from "./MetricContainer"
import * as $ from 'jquery';
import Slider from "./Slider"
export class ConfusionMatrix2 extends Component {
    render() {
        return (
            <div>
            <SelectBase></SelectBase>
            <Slider tolerence={this.props.tolerence} Set_tolerence={this.props.Set_tolerence}></Slider>
            <div style={{height:window.innerHeight-($("#top").height()+36),overflowY:"scroll"}}>
            {this.props.selected_metrics.map(metricName=><MetricContainer tolerence={this.props.tolerence} key={metricName} metricName={metricName}> </MetricContainer>)}
            </div>
            </div>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        selected_DT: state.selected_DT,
        selected_models: state.selected_models,
        category_names:state.category_names,
        selected_base:state.selected_base,
        subgroups:state.subgroups,
        selected_metrics:state.selected_metrics,
        tolerence:state.tolerence
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_scatter_plot_data: (val) => dispatch({ type: "scatter_plot_data", value: val }),
        Set_tolerence: (val) => dispatch({ type: "tolerence", value: val })
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(ConfusionMatrix2);

// https://mljs.github.io/confusion-matrix/
