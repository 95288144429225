import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from "./Slider"
import Bar from "./Bar"
import F1 from "./F1"
export default function ContinuousSlider(props) {
    var model_container_width = 160;
    var model_container_left_margin = 20;
    var model_container_padding = 5;
    return (
        <Grid container direction="column" justify="flext-start" alignItems="flext-start" style={{ width: model_container_width, marginLeft: model_container_left_margin, backgroundColor: "#fcfcfc", padding: model_container_padding }}>
            <h4 style={{ margin: 0, padding: 0, marginBottom: 5 }}>{props.model}</h4>
            {
                Object.entries(props.selected_DT[props.model]).map(item => <Grid><Slider subgroup={item[0]} Set_selected_DT={props.Set_selected_DT} selected_DT={props.selected_DT} model={props.model} item={item}></Slider></Grid>)
            }
            <h4 style={{margin: 0, padding: 0, marginBottom: 5 }}>Accuracy</h4>
            <Grid container direction="row" justify="flex-start" alignItems="center">
                {
                    Object.entries(props.selected_DT[props.model]).map(item => <Bar model_container_width={model_container_width-(2*model_container_padding)} model={props.model} subgroup={item[0]}></Bar>)
                }
            </Grid>
            <h4 style={{margin: 0, padding: 0, marginBottom: 5 }}>F1 Score</h4>
            <Grid container direction="row" justify="flex-start" alignItems="center" style={{}}>
                {
                    Object.entries(props.selected_DT[props.model]).map(item => <F1 type={"f1"} model_container_width={model_container_width-(2*model_container_padding)} model={props.model} subgroup={item[0]}></F1>)
                }
            </Grid>
        </Grid>
    );
}
