import React from "react";
import CSVReader from "react-csv-reader";
// in package.json "react-csv-reader": "3.0.4",
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  //transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};
const reader = (props)=>{
    return(
        <div className="container" style={{padding:15}}>
          <CSVReader
            cssClass="react-csv-input"
            label=""
            onFileLoaded={(data, fileInfo) => {
              props.ReadData(data)
              setTimeout(() => {  alert("Succesfully Uploaded"); }, 100);
            }}
            parserOptions={papaparseOptions}
          />
        </div>
      )
}
export default reader;
