import * as d3 from 'd3';
import { line } from 'd3';
import * as $ from 'jquery';
import ConfusionMatrix from 'ml-confusion-matrix';
export function CreateDemographicParity_and_EqualOpportunity(idName, x, y, selected_base, y_subgroup, selected_DT, selected_models, metricName,split_by,tolerence) {
    var symbol_data = {}
    if (metricName == "Demographic Parity") {
        selected_models.map(modelName => {
            console.log(selected_DT[modelName][selected_base],selected_DT[modelName][y_subgroup])
            var y_pred_selected_base = x.map(item => item[modelName] > selected_DT[modelName][selected_base] ? 1 : 0)
            var y_pred_y_subgroup = y.map(item => item[modelName] > selected_DT[modelName][y_subgroup] ? 1 : 0)
            var x2 = y_pred_selected_base.filter(item => item == 1)
            var y2 = y_pred_y_subgroup.filter(item => item == 1)
            symbol_data[modelName] = { "x": (x2.length * 100 / (x2.length + y2.length)), "y": (y2.length * 100 / (x2.length + y2.length)) }
        })
    }
    else if (metricName == "Equal Opportunity") {
        selected_models.map(modelName => {
            var y_pred_selected_base = x.map(item => item[modelName] > selected_DT[modelName][selected_base] ? {"y_pred":1,"y":item["y"]} : {"y_pred":0,"y":item["y"]})
            var y_pred_y_subgroup = y.map(item => item[modelName] > selected_DT[modelName][y_subgroup] ? {"y_pred":1,"y":item["y"]} : {"y_pred":0,"y":item["y"]})
            var x2 = y_pred_selected_base.filter(item => item['y_pred'] == 1 && item['y']==1)
            var y2 = y_pred_y_subgroup.filter(item => item['y_pred'] == 1 && item['y']==1)
            symbol_data[modelName] = { "x": (x2.length * 100 / (x2.length + y2.length)), "y": (y2.length * 100 / (x2.length + y2.length)) }
        })
    }
//--------------------------------------------------------------------------------------------------------Width and height
    var parent_width = $("#metric_container").width()-20, 
        parent_height = parent_width+20
    var margin = { top: 50, right: 20, bottom: 20, left: 30 };   // clockwise as in CSS
    var width = parent_width - margin.left - margin.right,       // width of plot inside margins
        height = parent_height - margin.top - margin.bottom;     // height   "     "

    var x = d3.scaleLinear().domain([0, 100]).range([0, width]);

    var y = d3.scaleLinear().domain([0, 100]).range([height, 0]);

    var xAxis = d3.axisBottom(x)
        .ticks(0)                            // request 5 ticks on the x axis

    var yAxis = d3.axisLeft(y)                // y Axis
        .ticks(0)

    var svg = d3.selectAll("#" + idName).attr("width", parent_width).attr("height", parent_height);
    var g = svg.selectAll(".myg").data([idName], d => d).join("g").attr("class", "myg").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    g.selectAll("g2").data([0]).join("g").attr("class", "y axis g2").call(yAxis);
    g.selectAll("g1").data([0]).join("g").attr("class", "x axis g1").attr("transform", "translate(0," + height + ")").call(xAxis);

    g.selectAll(".text1").data([0]).join("text").attr("class", "y label text1").attr("text-anchor", "middle").attr("x", -height/2).attr("y", -5).attr("transform", "rotate(-90)")
        .text(y_subgroup);
    g.selectAll(".text2").data([0]).join("text").attr("class", "x label text2").attr("text-anchor", "middle").attr("x", width/2).attr("y", height+margin.bottom)
        .text(selected_base);

    g.selectAll(".text3").data([0]).join("text").attr("class", "x label text3").attr("text-anchor", "middle").attr("x", width / 2).attr("y", -margin.top / 2).attr("dy", "+.75em")
        .text(metricName);
/*
    var line = d3.line().x(d => d[0]).y(d => d[1])
    g.selectAll(".mypath")                         // plot the data as a line
        .data([0]).join("path")
        .attr("class", "line mypath")
        .attr("d", line([[0, height], [width, 0]]))        // Call the function and pass the data
        .style('fill', 'none')
        .style('stroke', '#fff')
        .style('stroke', '#000')
*/
g.selectAll('.line_circle').data(d3.range(0,100,0.5)).join('circle').attr('class',"line_circle")
.attr('r',.5).attr('cx',d=>x(d)).attr('cy',d=>y(d)).attr('fill','black')
g.selectAll('.line_circle1').data(d3.range(0,100-tolerence,3)).join('circle').attr('class',"line_circle1").attr('r',.5)
.attr('cx',d=>x(d+1)).attr('cy',d=>y(d+tolerence)).attr('fill','black')

g.selectAll('.line_circle2').data(d3.range(tolerence,100,3)).join('circle').attr('class',"line_circle1").attr('r',.5)
.attr('cx',d=>x(d)).attr('cy',d=>y(d-tolerence)).attr('fill','black')


// ---------------------------------------------------------------------------------Add symbols
    var symbolGenerator = d3.symbol()
        .size(100);
    var symbolTypes = ['symbolCircle', 'symbolCross', 'symbolDiamond', 'symbolSquare', 'symbolStar', 'symbolTriangle', 'symbolWye'];
    g.selectAll('.mysymbols')
        .data(Object.entries(symbol_data))
        .join('g')
        .attr('class', "mysymbols")
        .attr('transform', function (d, i) {
            //var temp_x=(d[1]['x']-d3.min(xValue))/(d3.max(xValue)-d3.min(xValue))
            //var temp_y=(d[1]['y']-d3.min(yValue))/(d3.max(yValue)-d3.min(yValue))
            var temp_x = d[1]['x']
            var temp_y = d[1]['y']
            return 'translate(' + x(temp_x) + ',' + y(temp_y) + ')';
        })
        .attr("dummy", function (d, i) {
            d3.select(this).selectAll('path').data([0]).join('path')
                .attr('d', function () {
                    symbolGenerator.type(d3[symbolTypes[i]]);
                    return symbolGenerator();
                });
        })


}

export function downlaod(myurl, data) {
    return fetch(myurl, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
        .then(response => {
            return response;
        }).catch(error => console.error('Error: from Json Handler', error));
}
export default CreateDemographicParity_and_EqualOpportunity;