import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
function FadeMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //------------For list and chckbox
  const [checked, setChecked] = React.useState([0]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
//----------------
  const checkboxChanged=(event)=>{   
    var temp;
    if(props.selected_models.includes(event.target.value)){
      temp=props.selected_models.filter(item=>{
        if(item!=event.target.value) 
        return item
      })
      props.Set_defualt_models(temp)      
    }
    else{
      props.Set_defualt_models([...props.selected_models,event.target.value])
    }
    
  }
  return (
        <div>
            <Button style={{borderRadius:0}} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                Select Model
                <ArrowDropDownIcon></ArrowDropDownIcon>
      </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div className="CheckboxContainer">
                    <List>
                        {props.model_list.map((value) => {
                            const labelId = `checkbox-list-label-${value}`;
                            return (
                                <ListItem key={value} role={undefined} onClick={handleToggle(value)} style={{padding:"0px 15px"}}>
                                    <Checkbox
                                        checked={props.selected_models.includes(value)}
                                        edge="start"
                                        tabIndex={-1}
                                        value={value}
                                        onChange={(event,value)=>{
                                            checkboxChanged(event,value)}
                                          }
                                    />
                                    <p className="list_item_label" id={labelId}>{value}</p>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </Menu>
        </div>
    );
}
const maptstateToprop = (state) => {
    return {
        selected_models: state.selected_models,
        model_list: state.model_list,
        selected_DT: state.selected_DT,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_defualt_models: (val) => dispatch({ type: "selected_models", value: val }),
        Set_selected_DT: (val) => dispatch({ type: "selected_DT", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(FadeMenu);
//https://material-ui.com/components/menus/
