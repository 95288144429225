let initialState = {
    config:{DataClusterWidth:400,LimeClusterWidth:450,cluster_cell_height:30},
    temp: 10,
    original_data: [], // This is the original data that we never change
    selected_data: [], // This is the filtered data based on the selection on the histogram
    model_list: ["Random Forest", "Logistic Regression", "K Nearest Neighbor", "Support Vector Classifier", "Decision Tree", "Naive Bayes"], // This is the list of supported models
    models: ['None'], // This is outdated
    selected_models: [], // This is the selected models
    category_names: ['gender', 'race'],
    categories: null,
    split_by: "gender",
    open_uploader: false,
    DTs: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    selected_DT:{"Random Forest":{"Male":0.5,"Female":0.5},"Logistic Regression":{"Male":0.5,"Female":0.5},"K Nearest Neighbor":{"Male":0.5,"Female":0.5},"Support Vector Classifier":{"Male":0.5,"Female":0.5},"Decision Tree":{"Male":0.5,"Female":0.5},"Naive Bayes":{"Male":0.5,"Female":0.5}},
    feature_list: [],
    selected_rects: {},
    cluster_data_filtered: {},
    scatter_plot_data: [],
    tSne_data: {},
    selected_base: "Male",
    subgroups: [],
    selected_metrics: ["Demographic Parity", "Equal Opportunity"],
    lime_data: [],
    model_colors: { "Random Forest": '#66c2a5', "Logistic Regression": '#fc8d62', "K Nearest Neighbor": '#8da0cb', "Support Vector Classifier": '#e78ac3', "Decision Tree": '#a6d854', "Naive Bayes": '#ffd92f' },
    lime_model:"Logistic Regression",
    selected_cluster:[],
    tolerence:20,
    //url: "http://localhost:5000",
    url:"http://3.133.100.77:5000",
}

const reducer = (state = initialState, action) => {  //action={ type: "selected_DT", value: val }
    if (action.type === "tolerence") {
        console.log(action.value)
        return { ...state, tolerence: action.value }
    }
    if (action.type === "lime_model") {
        //console.log(action.value)
        return { ...state, lime_model: action.value }
    }
    if (action.type === "selected_cluster") {
        //console.log(action.value)
        return { ...state, selected_cluster: action.value }
    }
    if (action.type === "temp") {
        return { ...state, temp: action.value }
    }
    if (action.type === "lime_data") {
        return { ...state, lime_data: action.value }
    }
    if (action.type === "selected_metrics") {
        return { ...state, selected_metrics: action.value }
    }
    if (action.type === "subgroups") {
        return { ...state, subgroups: action.value }
    }
    if (action.type === "original_data") {
        return { ...state, original_data: action.value }
    }
    if (action.type === "selected_data") {
        return { ...state, selected_data: action.value }
    }
    if (action.type === "selected_models") {
        return { ...state, selected_models: action.value }
    }
    if (action.type === "models") {
        return { ...state, models: action.value }
    }
    if (action.type === "category_names") {
        return { ...state, category_names: action.value }
    }
    if (action.type === "categories") {
        return { ...state, categories: action.value }
    }
    if (action.type === "split_by") {
        return { ...state, split_by: action.value }
    }
    if (action.type === "open_uploader") {
        return { ...state, open_uploader: action.value }
    }
    if (action.type === "DTs") {
        return { ...state, DTs: action.value }
    }
    if (action.type === "selected_DT") {
        return { ...state, selected_DT: action.value }
    }
    if (action.type === "feature_list") {
        return { ...state, feature_list: action.value }
    }
    if (action.type === "selected_rects") {
        return { ...state, selected_rects: action.value }
    }
    if (action.type === "cluster_data_filtered") {
        return { ...state, cluster_data_filtered: action.value }
    }
    if (action.type === "scatter_plot_data") {

        return { ...state, scatter_plot_data: action.value }
    }
    if (action.type === "tSne_data") {
        return { ...state, tSne_data: action.value }
    }
    if (action.type === "selected_base") {
        return { ...state, selected_base: action.value }
    }
    return state;
}
export default reducer;