import React, { Component } from 'react'
import Header from "./components/Header/Header"
import TopBar from "./components/Upload/TopBar"
import Grid from '@material-ui/core/Grid';
import ConfusionMatrixContainer from './components/ConfusionMatrix/ConfusionMatrixContainer'
import SliderRootContainer from './components/SliderBars/RootContainer'
import Uploader from './components/Upload/Uploader'
import { connect } from 'react-redux'
import Histogramscontainer from './components/Histograms/Histogramscontainer'
import * as algo1 from "./Algorithms/Algo1"
import * as d3 from 'd3';
import * as $ from 'jquery';
import Scatterplot from './components/Scatterplot/Scatterplot'
import ScatterplotLime from './components/Scatterplot/ScatterplotLime'
import ClusterContainer from './components/Clusters/ClusterContainer'
import MetricParent from "./components/Metrics/MetricParent"
// Import all datasets
import tSne_data from "./data/adult/scatter_plot_data.json"
import adult_data from "./data/adult/adult_data.csv"
import compas_data from "./data/compas/compas_data.csv"
import compas_lime from "./data/compas/compas_lime_data.csv"
// Import all datasets

export class App extends Component {
  constructor(props) {
    super(props)
    this.state = { selected_data: null }
  }
  componentDidMount() {
    this.props.Set_tSne_data(tSne_data)
    this.dataprocessor("compas")

  }
  //--------- data processor processes data for initial render
  dataprocessor = (dataset_name) => {
    if (dataset_name == "adult") { this.process_data(adult_data, compas_lime) }
    if (dataset_name == "crime") { this.process_data(adult_data, compas_lime) }
    if (dataset_name == "compas") { this.process_data(compas_data, compas_lime) }
  }
  process_data = (filename, lime_data_filename) => {
    //-------------Read the original data
    d3.csv(filename).then(data => {
      this.props.Set_original_data(data)
      this.props.Set_selected_data(data)
      var new_models = data.columns.filter(item => this.props.model_list.includes(item))
      this.props.Set_model_list(algo1.getModels(new_models))
      this.props.Set_selected_models(new_models.slice(0, 3))
      var feature_list = data.columns.filter(item => !this.props.model_list.includes(item) && item != "y" && item != 'index')
      this.props.Set_feature_list(feature_list)
      this.props.Set_categories(algo1.getCategories(data, this.props.category_names))
      this.props.Set_subgroups(algo1.getSubgroups(data, this.props.category_names[0])) // this.props.category_names[0] is split_by
    })
    //-------------Read the lime data
    d3.csv(lime_data_filename).then(data => {
      var nested_data = {}
      d3.nest().key(function (d) { return d.model; }).entries(data).map(item => {
        nested_data[item.key] = item.values
      })
      this.props.Set_lime_data(nested_data)
    })
  }
  //---------
  render() {
    return (
      <Grid container direction="row" spacing={0}>
        <Grid id="top" item xs={12}>
          <TopBar></TopBar>
          <Header></Header>
        </Grid>
        <Grid container direction="col">
          {
            // ----------------------------------------------------------------------- ROC and Histogram are handled here
          }
          <Grid item className="feature_container" xs={2} style={{ backgroundColor: "#f7f7f7" }}>
            {this.props.original_data.length > 0 && this.props.feature_list.length > 0 ? <Histogramscontainer original_data={this.props.original_data} feature_list={this.props.feature_list}></Histogramscontainer> : null}
          </Grid>
          {
            // ----------------------------------------------------------------------- Confusion matrix and the Scatterplot starts here
          }
          <Grid id="confusion_matrices_and_scatterplot" container direction="row" xs={8} style={{ height: window.innerHeight - $("#top").height(), overflow: "scroll", borderLeft: "1px solid #d6d6d6", borderRight: "1px solid #d6d6d6" }}>
            <Grid container direction="column" >
              <Grid>
                {this.props.split_by != "None" ? <SliderRootContainer></SliderRootContainer> : null}
              </Grid>
              {
                /*
              <Grid className="grid1_conf_mat" item style={{ maxHeight: $("#confusion_matrices_and_scatterplot").height() * .6, overflow: "scroll" }}>
                {this.props.selected_data.length > 0 && this.props.selected_models.length > 0 ? <ConfusionMatrixContainer selected_data={this.props.selected_data}></ConfusionMatrixContainer> : null}
              </Grid>
                */
              }
              {
                
                <ClusterContainer></ClusterContainer>
                
              }
            </Grid>
          </Grid>
          {
            // ----------------------------------------------------------------------- All Metrics starts here
          }
          <Grid id="metric_container" xs={2} style={{ backgroundColor: "#f7f7f7" }}>
            {
              this.props.subgroups.length > 0 ? <MetricParent selected_data={this.props.original_data.length>0?this.props.selected_data:this.props.original_data} split_by={this.props.split_by=="None"?this.props.category_names[0]:this.props.split_by}>  </MetricParent> : null
            }
          </Grid>
        </Grid>
        <Uploader ReadData={this.ReadData}></Uploader>
      </Grid >
    )
  }
}
const maptstateToprop = (state) => {
  return {
    model_list: state.model_list,
    selected_models: state.selected_models,
    split_by: state.split_by,
    categories: state.categories,
    category_names: state.category_names,
    selected_data: state.selected_data,
    original_data: state.original_data,
    feature_list: state.feature_list,
    tSne_data: state.tSne_data,
    subgroups: state.subgroups,
    lime_data: state.lime_data,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_original_data: (val) => dispatch({ type: "original_data", value: val }),
    Set_selected_data: (val) => dispatch({ type: "selected_data", value: val }),
    Set_selected_models: (val) => dispatch({ type: "selected_models", value: val }),
    Set_model_list: (val) => dispatch({ type: "model_list", value: val }),
    Set_category_names: (val) => dispatch({ type: "category_names", value: val }),
    Set_categories: (val) => dispatch({ type: "categories", value: val }),
    Set_split_by: (val) => dispatch({ type: "split_by", value: val }),
    Set_open_uploader: (val) => dispatch({ type: "open_uploader", value: val }),
    Set_DTs: (val) => dispatch({ type: "DTs", value: val }),
    Set_selected_DT: (val) => dispatch({ type: "selected_DT", value: val }),
    Set_feature_list: (val) => dispatch({ type: "feature_list", value: val }),
    Set_tSne_data: (val) => dispatch({ type: "tSne_data", value: val }),
    Set_subgroups: (val) => dispatch({ type: "subgroups", value: val }),
    Set_selected_base: (val) => dispatch({ type: "selected_base", value: val }),
    Set_lime_data: (val) => dispatch({ type: "lime_data", value: val }),
    Set_selected_DT: (val) => dispatch({ type: "selected_DT", value: val }),
    Set_split_by: (val) => dispatch({ type: "split_by", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);
