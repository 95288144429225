import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //------------For list and chckbox
  const handleChange = (event) => {
    props.Set_selected_base(event.target.value)
  };
  //---------------- Get the radio options for the radio button
  return (
    <div style={{backgroundColor:"#e0e0e0"}}>
      <Button style={{ borderRadius: 0,width:"100%" }} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        Select Base
        <ArrowDropDownIcon></ArrowDropDownIcon>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <div>
        <RadioGroup aria-label="gender" name="gender1" value={props.selected_base} onChange={handleChange}>
            {props.subgroups.map((value) => {
              return (
                <ListItem key={value} role={undefined} style={{ padding: "0px 15px" }}>
                  <FormControlLabel value={value} control={<Radio />} label={value} />
                </ListItem>
              );
            })}
          </RadioGroup>
        </div>
      </Menu>
    </div>
  );
}
const maptstateToprop = (state) => {
  return {
    selected_models: state.selected_models,
    selected_DT: state.selected_DT,
    original_data: state.original_data,
    split_by: state.split_by,
    category_names: state.category_names,
    subgroups:state.subgroups,
    selected_base:state.selected_base,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_selected_base: (val) => dispatch({ type: "selected_base", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(FadeMenu);
//https://material-ui.com/components/menus/
