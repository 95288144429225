import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Split from "./RadioDropDown"
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 50,
    },
  },
}));

export default function BasicTextFields(props) {
  const classes = useStyles();
  const [number, Set_number] = useState(props.number_of_clusters);
  return (
    <Grid style={{backgroundColor:"",marginTop:-5,marginLeft:0}} container direction="row" justify="center" alignItems="center">
    <form className={classes.root} noValidate autoComplete="off">
      <TextField id="standard-basic" className="text1" label="" defaultValue={props.number_of_clusters}
      onChange={(event)=>Set_number(event.target.value)}
      />
    </form>
    <Split></Split>
    <Button style={{height:30,borderRadius:2,backgroundColor:"#e5e5e5",marginBottom:-4,textTransform:"none"}} variant="contained" color="primary" disableElevation
    onClick={()=>{
      props.Set_selected_cluster([])
      props.set_number_of_clusters(number)
    }}
    >Make clusters</Button>
    </Grid>
  );
}