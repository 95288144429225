import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}°C`;
}
export default function FlairSentimentScoreSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.tolerence);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.Set_tolerence(newValue)
  };
  const commitChange = (event, newValue) => {
    //var temp_selected_DT=JSON.parse(JSON.stringify(props.selected_DT))

  };
  return (
    <Grid container style={{width:"100%",marginTop:4,marginLeft:0}} direction="row" justify="center" alignItems="flex-start">
      <Grid item><p style={{padding:0,margin:0,marginRight:10}}>Tolerence</p></Grid> 
      <Grid item xs={4} style={{marginTop:-1}}>
      <Slider
        value={value}
        valueLabelDisplay="off"
        onChange={handleChange}
        onChangeCommitted={commitChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={1}
        min={0}
        max={100}
      />
      </Grid>
    </Grid>
  );
}