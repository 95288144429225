import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import * as d3 from 'd3';
import * as algo1 from '../../../Algorithms/Algo1'
import Textbox1 from "./Textbox1"
export class App extends Component {
    constructor(props) {
        super(props)
        this.state = { number_of_clusters: 5 }
    }
    componentDidMount() {
        this.setState({ a: 10 })
    }
    componentDidUpdate() {
        var my_inds = this.props.selected_data.map(item => parseInt(item["index"]))
        var data = { "dataset": "compas", "k": this.state.number_of_clusters,split_by: this.props.split_by}
        data['my_inds'] = my_inds
        var response = algo1.downlaod(this.props.url + "/get_cluster", data)
        var temp = []
        response.then(cluster_data => {
            //----------------------
            cluster_data["response"].map(element => {
                this.props.selected_data.map(item => {
                    if (item['index'] == element[0]) {
                        item['cluster'] = element[1]
                        temp.push(item)
                    }
                })
            })
            //----------------------
            var clustered = d3.nest()
                .key(function (d) { return d.cluster; })
                .entries(temp);
            algo1.make_cluster_vis(this.props.config,clustered, this.props.subgroups, this.props.split_by, "cluster_container1",this.props.Set_selected_cluster)
        })

    }
    set_number_of_clusters=(number)=>{
        this.setState({number_of_clusters:parseInt(number)})
    }
    render() {
        return (
            <Grid container direction="row" spacing={0} style={{ paddingLeft:3,marginTop: 10, marginLeft: 10, border: "1px solid #e0e0e0", width: this.props.config.DataClusterWidth }}>
                <Textbox1 Set_selected_cluster={this.props.Set_selected_cluster} set_number_of_clusters={this.set_number_of_clusters} number_of_clusters={this.state.number_of_clusters}></Textbox1>
                <svg className="cluster_container1" style={{marginBottom:7,marginRight:7}}> </svg>
            </Grid >
        )
    }
}

export default App;
