import React, { Component } from 'react'
import NonNumHistogram from './NonNumHistogram'
import NumHistogram from './NumHistogram'
import * as d3 from 'd3';
import * as $ from 'jquery';

export default class Histogramscontainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            non_num:[],num:[],
            }
    }
    componentDidMount() {
        var non_num = [],num = []
        this.props.feature_list.map(item => {
            if (isNaN(this.props.original_data[0][item])) {
                non_num.push(item)
            }
            else {
                num.push(item)
            }
        })
        this.setState({non_num:non_num,num:num})
    }
    render() {
        return (
            <div style={{height:window.innerHeight-($("#top").height()+$(".mysvg").height()), overflow:"scroll"}}>
                {this.state.non_num.length>0?this.state.non_num.map(feature=><NonNumHistogram title={feature} feature_id={feature.replace(/\s/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')} original_data={this.props.original_data} feature_list={this.props.feature_list}></NonNumHistogram>):null}
                {this.state.num.length>0?this.state.num.map(feature=><NumHistogram title={feature} feature_id={feature.replace(/\s/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')} original_data={this.props.original_data} feature_list={this.props.feature_list}></NumHistogram>):null}               
            </div>
        )
    }
}
