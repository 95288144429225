import React, { Component } from 'react'
import * as d3 from 'd3';
import * as d3lasso from 'd3-lasso';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid"
import * as $ from 'jquery';
export class Scatterplot extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_datasets: {}, temp: [],
        }
    }
    componentDidMount() {
        this.setState({ temp: null })
        //this.create_scatter_plot(Object.entries(this.props.tSne_data))
    }
    componentDidUpdate() {
        this.create_scatter_plot(Object.entries(this.props.tSne_data))
    }
    create_scatter_plot = (scatter_plot_data_arr) => {
        var parent_height = 250, parent_width = $(".myscatterplot").width()
        var margin = { top: 0, right: 10, bottom: 0, left: 10 }, width = parent_width - margin.left - margin.right, height = parent_height - margin.top - margin.bottom;

        var tooltip = d3.select("body").append("div").attr("class", "tooltip").style("opacity", 0);

        // append the svg object to the body of the page
        var svg = d3.select("#my_dataviz").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom)

        // Add X axis
        var temp_x = scatter_plot_data_arr.map(d => d[1][0]) // d[1]
        var x = d3.scaleLinear().domain([d3.min(temp_x), d3.max(temp_x)]).range([10, width]);

        // Add Y axis
        var temp_y = scatter_plot_data_arr.map(d => d[1][1])
        var y = d3.scaleLinear().domain([d3.min(temp_y), d3.max(temp_y)]).range([height, 10]);

        //console.log(scatter_plot_data_arr)
        svg.selectAll("circle").data(scatter_plot_data_arr, (d, i) => typeof d != "undefined" ? d[0] : 0)
            .join("circle").attr("cx", d => x(d[1][0])).attr("cy", d => y(d[1][1])).attr("r", 4)
            .attr("class", d => "scat scat" + d[0]).attr("fill", "#545454").attr("opacity", 1)

        var lasso = d3lasso.lasso().closePathSelect(true).closePathDistance(100)
            .items(svg.selectAll('circle')).targetArea(svg)
            .on("start", () => {
                lasso.items().attr("r", 4).classed("not_possible", true).classed("selected", false);
            })
            .on("draw", () => {
                // Style the possible dots
                lasso.possibleItems().classed("not_possible", false).classed("possible", true);
                // Style the not possible dot
                lasso.notPossibleItems().classed("not_possible", true).classed("possible", false);
            })
            .on("end", () => {
                var selected_data=lasso.selectedItems().data().map(item => item[0])
                d3.selectAll(".scat").attr('opacity', 0.02)
                selected_data.map(item => d3.selectAll(".scat" + item).attr('opacity', 1))
                lasso.items().classed("not_possible", false).classed("possible", false);
                // Style the selected dots
                lasso.selectedItems().classed("selected", true).attr("border", "1px solid black").attr("r", 4);
                // Reset the style of the not selected dots
                lasso.notSelectedItems().attr("opacity", 0.2).attr("r", 4);
            })
        svg.call(lasso);
    }
    render() {
        return (
            <Grid className="myscatterplot" item xs={5}
                container
                direction="column"
                justify="center"
                alignItems="center" style={{ marginTop: 0, marginBottom: 0 }}> {/* earlier it was display: flex */}
                <svg id="my_dataviz"></svg>
            </Grid>
        )
    }
}

const maptstateToprop = (state) => {
    return {
        //scatter_plot_data: state.scatter_plot_data,
        tSne_data: state.tSne_data,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
    }
}

export default connect(maptstateToprop, mapdispatchToprop)(Scatterplot);
