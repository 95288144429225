import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}°C`;
}
export default function FlairSentimentScoreSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.item[1]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    var temp_selected_DT=JSON.parse(JSON.stringify(props.selected_DT))
    temp_selected_DT[props.model][props.subgroup]=newValue
    props.Set_selected_DT(temp_selected_DT)
  };
  const commitChange = (event, newValue) => {
    var temp_selected_DT=JSON.parse(JSON.stringify(props.selected_DT))
    temp_selected_DT[props.model][props.subgroup]=newValue
    props.Set_selected_DT(temp_selected_DT)
  };
  return (
    <Grid container style={{width:"100%",marginTop:4,marginLeft:0}} direction="row" justify="space-around" alignItems="flex-start">
      <Grid item xs={6}><p style={{padding:0,margin:0}}>{props.subgroup}</p></Grid> 
      <Grid item xs={6} style={{marginTop:-1}}>
      <Slider
        value={value}
        onChange={handleChange}
        onChangeCommitted={commitChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={0.001}
        min={0}
        max={1}
      />
      </Grid>
    </Grid>
  );
}