import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import {connect} from 'react-redux'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function SimpleSelect(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState(props.selected_DT);

  const handleChange = (event) => {
    setAge(event.target.value);
    props.Set_selected_DT(event.target.value)

  };

  return (
    <ThemeProvider theme={theme}>
    <div>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.selected_DT.toString()}
          onChange={handleChange}
        >
          {props.DTs.map((val)=><MenuItem value={val}>{val}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
    </ThemeProvider>
  );
}
const maptstateToprop = (state) => {
  return {
    DTs: state.DTs,
    selected_DT: state.selected_DT,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_selected_DT: (val) => dispatch({ type: "selected_DT", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(SimpleSelect);

