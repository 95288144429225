import { createMuiTheme } from '@material-ui/core/styles';
import { grey, deepPurple, amber } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[500],
    },
    secondary: {
      main: "#565555",
      contrastText: "#727272", // This will change the color of radio button
    },
  },
});

theme.props = {
  MuiInputLabel: {
    shrink: true,
  },
  MuiInput: {
    disableUnderline: false,
  },
  MuiTooltip: {
    arrow: true,
  },
};

theme.overrides = {
  MuiInput: {
    root: {
      minHeight:20,
      height:25,
      minWidth:50,
      width:50,
      fontSize:14,
      top: theme.spacing(0),
      border: `none`,
      outline: `1px solid transparent`,
      paddingLeft: 5, // move the text in the input
      paddingTop: 2, // move the text in the input
      marginRight: 5,
      '&$focused': {
        border: `none`,
        outline: `none`,
      },
    },
  },
};

export default theme;
