import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as MetricAlgo from "./MetricAlgo"

export class ConfusionMatrix2 extends Component {
    constructor(props) {
        super(props)
        this.state = { selected_base: null, dummy: null }
    }
    componentDidMount() {
        this.setState({ dummy: 10 }) // Change the state so that the componentDidUpdate is called. 
    }
    componentDidUpdate() {
        var x = []
        var y = []
        var split_by = ""
        if (this.props.split_by == "None") { split_by = this.props.category_names[0] } else { split_by = this.props.split_by }
        this.props.original_data.map(item => {
            if (item[split_by] == this.props.selected_base) {
                x.push(item)
            }
            else if (item[split_by] == this.props.y_subgroup) {
                y.push(item)
            }
        })
        MetricAlgo.CreateDemographicParity_and_EqualOpportunity((this.props.y_subgroup + this.props.metricName).replace(/\s/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''), x, y, this.props.selected_base, this.props.y_subgroup, this.props.selected_DT, this.props.selected_models, this.props.metricName,this.props.split_by,this.props.tolerence)
    }
    //----------------------------------------
    render() {
        return (
            <div>
                <svg id={(this.props.y_subgroup + this.props.metricName).replace(/\s/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')}> </svg>
            </div>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        selected_DT: state.selected_DT,
        split_by: state.split_by,
        category_names: state.category_names,
        subgroups: state.subgroups,
        selected_base: state.selected_base,
        selected_models: state.selected_models,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_scatter_plot_data: (val) => dispatch({ type: "scatter_plot_data", value: val })
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(ConfusionMatrix2);

// https://mljs.github.io/confusion-matrix/
