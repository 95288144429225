import React, { Component } from 'react'
import * as d3 from 'd3';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid"
import * as $ from 'jquery';
import Split from "./Split"
import SetModel from "./SetModel"
export class Scatterplot extends Component {
    constructor(props) {
        super(props)
        this.state = { selected_datasets: {}, feature: "gender", subgroups: [], lime_models: [] }
    }
    componentDidMount() {
        //console.log(this.props.lime_data['Decision Tree'])
        this.setState({ subgroups: this.props.subgroups })
        this.setState({ lime_models: this.props.selected_models })
        //this.create_scatter_plot(this.props.lime_data['Decision Tree'])
    }
    set_feauture = (feature, subgroups) => {
        this.setState({ feature: feature })
        this.setState({ subgroups: subgroups })
    }
    set_lime_models = (lime_models) => { this.setState({ lime_models: lime_models }) }

    componentDidUpdate() {
        var feature = this.state.feature
        var temp_colors = ['#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00'], colors = {}
        this.state.subgroups.map((d, i) => colors[d] = temp_colors[i])
        //----------Filter lime data starts here
        var filtered_by_model = {}
        this.state.lime_models.map(model => {
            filtered_by_model[model] = this.props.lime_data[model].sort((a, b) => (parseFloat(a[feature]) < parseFloat(b[feature])) ? 1 : -1)
        })
        var merged_arr = [].concat.apply([], Object.values(filtered_by_model))
        //----------Filter lime data ends here        
        this.create_scatter_plot(merged_arr, filtered_by_model, feature, colors)
    }
    create_scatter_plot = (data_arr, filtered_by_model, feature, colors) => {
        var scatterplot_data = Object.entries(filtered_by_model)
        var parent_height = 250
        var parent_width = $(".mylimescatterplot").width()
        var tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);
        var margin = { top: 10, right: 100, bottom: 30, left: 50 },
            width = parent_width - margin.left - margin.right,
            height = parent_height - margin.top - margin.bottom;

        // append the svg object to the body of the page
        var svg = d3.select("#my_lime_dataviz").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom)
            .selectAll(".mysvgg").data([0]).join('g').attr('class', 'mysvgg').attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        // Add Y axis
        var temp_y_all = data_arr.map(d => parseFloat(d[feature]))
        var y_all = d3.scaleLinear().domain([d3.min(temp_y_all), d3.max(temp_y_all)]).range([height, 10]).nice();
        svg.selectAll(".myYaxis").data([0]).join('g').attr('class', 'myYaxis').call(d3.axisLeft(y_all).ticks(4));

        //------------------------------------------------------------------------------------------------------ Scatterplot starts here
        svg.selectAll(".scatterplot_g").data(scatterplot_data).join('g').attr("id", d => d[0] + "scatterplot_g_id").attr("class", "scatterplot_g").attr("ax", function (d) {
            //console.log(d)
            var temp_x = d[1].map(d => parseInt(d['index']))
            var temp_y = d[1].map(d => parseFloat(d[feature]))

            var x = d3.scaleLinear().domain([d3.min(temp_x), d3.max(temp_x)]).range([10, width]).nice()
            var y = d3.scaleLinear().domain([d3.min(temp_y), d3.max(temp_y)]).range([height, 15]).nice();
            //console.log(d3.select(this))
            d3.select(this).selectAll('circle').data(d[1])
                .join("circle")
                .attr("cx", (d, i) => x(i))
                .attr("cy", d => y(d[feature]))
                .attr("r", 4)
                .attr("class", d => "scat scat" + d['index'])
                .attr("fill", d => colors[d[feature + '_val']])
                .attr("opacity", 1)

        })
        //------------------------------------------------------------------------------------------------------ Scatterplot ends here

        //------------------------------------------------------------------------------------------------------ Legend starts here
        var keys = this.state.subgroups
        var color = colors
        var x=width
        var y=10
        var size = 10
        svg.selectAll(".mydots")
            .data(keys)
            .join("rect").attr('class',"mydots")
            .attr("x", x)
            .attr("y", function (d, i) { return y + i * (size + 5) })
            .attr("width", size)
            .attr("height", size)
            .style("fill", function (d) { return color[d] })

        svg.selectAll(".mylabels")
            .data(keys)
            .join("text").attr('class',"mylabels")
            .attr("x", x + size * 1.2)
            .attr("y", function (d, i) { return y + i * (size + 5) + (size / 2) })
            .style("fill", function (d) { return color[d] })
            .text(function (d) { return d })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .style('font-size',10)
        //------------------------------------------------------------------------------------------------------ Legend ends here

    }
    render() {
        return (
            <Grid className="mylimescatterplot" container xs="6" style={{ marginLeft: 0, marginBottom: 0, backgroundColor: "#f4f4f4" }} direction="row" justify="center" alignItems="center">
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    style={{ borderBottom: "1px solid #d3d3d3" }}>
                    <Split set_feauture={this.set_feauture} default_feature={this.state.feature}></Split>
                    <SetModel set_lime_models={this.set_lime_models} lime_models={this.state.lime_models}> </SetModel>
                </Grid>
                <svg id="my_lime_dataviz"></svg>
            </Grid>
        )
    }
}

const maptstateToprop = (state) => {
    return {
        lime_data: state.lime_data,
        subgroups: state.subgroups,
        feature: state.feature,
        selected_models: state.selected_models,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(Scatterplot);



//https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/